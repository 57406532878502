import React, { useContext, useEffect, useState } from "react";
import "./user.css";
import { IoSearchOutline, IoEyeSharp } from "react-icons/io5";
import { useNavigate, useLocation } from "react-router-dom";
import VisaContext from "../../../context/visa-context";
import MyLoader from "../../Loader/MyLoader";

const User = () => {
  const ctxApi = useContext(VisaContext);
  const navigate = useNavigate();
  const [users, setUsers] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredUsers, setFilteredUsers] = useState([]);
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const highlightedUserIds = searchParams.get("ids")
    ? searchParams.get("ids").split(",")
    : [];

  useEffect(() => {
    const fetchUsers = async () => {
      ctxApi.setLoading(true);
      try {
        const response = await fetch("https://backend-visa2.vercel.app/adminApi/users");
        if (!response.ok) {
          ctxApi.setLoading(false);
          throw new Error("Network response was not ok");
        }
        ctxApi.setLoading(false);
        const data = await response.json();
        if (Array.isArray(data)) {
          const reversedData = data.reverse();
          setUsers(reversedData); // Reverse to show latest applications first
          setFilteredUsers(reversedData); // Reverse to show latest applications first
        } else {
        }
      } catch (error) {
        ctxApi.setLoading(false);
        console.error("Error fetching users:", error);
      }
    };
    fetchUsers();
  }, []);

  useEffect(() => {
    const results = users.filter(
      (user) =>
        user.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
        user.email.toLowerCase().includes(searchTerm.toLowerCase())
    );
    setFilteredUsers(results);
  }, [searchTerm, users]);


  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
  };

  const handleUserClick = (userId) => {
    navigate("/userdashboard", { state: { userId } });
  };

  return (
    <main className="main-container">
      {ctxApi.isLoading && <MyLoader />}
      <div className="main-title">
        <h3 style={{ color: "#13e313", fontSize: "25px" }}>Vijatour Users</h3>
      </div>
      <div className="searchInput">
        <div className="inputBtn">
          <IoSearchOutline />
          <input
            type="text"
            placeholder="Search by name or email"
            value={searchTerm}
            onChange={handleSearch}
          />
        </div>
      </div>
      <div className="userpage">
        <table>
          <thead>
            <tr>
              <th>Name</th>
              <th>Last Name</th>
              <th>Mobile No.</th>
              <th>Email</th>
              <th>Company Name</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {filteredUsers.map((user) => (
              <tr key={user._id}>
                <td>
                {user.name}
                {highlightedUserIds.includes(user._id.toString()) && (
                      <span
                        style={{
                          color: "red",
                          marginLeft: "5px",
                          fontSize: "12px",
                          fontWeight: "500",
                          padding: "3px 3px",
                          backgroundColor: "skyblue",
                          borderRadius: "3px",
                        }}
                      >
                        New
                      </span>
                    )}
                </td>
                <td>{user.lname}</td>
                <td>{user.Cnomber}</td>
                <td>{user.email}</td>
                <td>{user.Cname}</td>
                <td>
                  <button
                    style={{
                      border: "none",
                      backgroundColor: "transparent",
                      cursor: "pointer",
                    }}
                    onClick={() => handleUserClick(user._id)}
                  >
                    <IoEyeSharp
                      style={{ color: "white", fontSize: "21px" }}
                      className="eye_iconHover"
                    />
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </main>
  );
};

export default User;

