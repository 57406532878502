import React, { useContext, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { IoEyeSharp } from "react-icons/io5";
import "./user.css";
import VisaContext from "../../../context/visa-context";
import MyLoader from "../../Loader/MyLoader";
import { FaAmazonPay } from "react-icons/fa6";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import Button from "@mui/material/Button";
import { RiInformation2Line } from "react-icons/ri";
import { styled } from "@mui/material/styles";

const UserDetails = () => {
  const ctxApi = useContext(VisaContext);
  const navigate = useNavigate();
  const location = useLocation();
  const userId = location.state?.userId;
  const [selectedUserApplication, setSelectedUserApplication] = useState(null);
const applications = ctxApi.customers;


  useEffect(() => {
    if (selectedUserApplication) {
      navigate("/userDocuments", {
        state: { application: selectedUserApplication },
      });
    }
  }, [selectedUserApplication, navigate]);

  const userApplications = applications
    .map((application) =>
      application.data.filter((datas) => datas.userId === userId)
    )
    .flat();

  if (!userApplications) {
    return <div>No applications found for this user.</div>;
  }

  const handleSendDocument = (application) => {
    setSelectedUserApplication(application);
  };

  const handleViewApplications = (travelerId) => {
    navigate("/userpayment", { state: { travelerId } });
  };

    // HtmlTooltip style  💯
    const HtmlTooltip = styled(({ className, ...props }) => (
      <Tooltip {...props} classes={{ popper: className }} />
    ))(({ theme }) => ({
      [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: "#f5f5f9",
        color: "rgba(0, 0, 0, 0.87)",
        maxWidth: 220,
        fontSize: theme.typography.pxToRem(12),
        border: "1px solid #dadde9",
        padding: "10px",
      },
    }));

  return (
    <>
      <main className="main-container">
        {ctxApi.isLoading && <MyLoader />}
        <div className="main-title11">
          <h3 style={{ color: "#13e313", fontSize: "25px" }}>
            User Informations
          </h3>
        </div>
        <div className="userpage">
          <table>
            <thead>
              <tr>
                <th>Name</th>
                <th>Last Name</th>
                <th>Email</th>
                <th>Contact No.</th>
                <th>DOB</th>
                <th>Expected Date</th>
                <th>Passport No.</th>
                <th>Destination</th>
                <th>Details</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {userApplications.map((datas, ind) => {
                return (
                  <tr key={ind}>
                    <td>{datas.name}</td>
                    <td>{datas.lname}</td>
                    <td>{datas.email}</td>
                    <td>{datas.contactNo}</td>
                    <td>{datas.dob}</td>
                    <td>{datas.expectedDate}</td>
                    <td>{datas.passportNo}</td>
                    <td>{datas.destination}</td>
                    <td>
                    <HtmlTooltip
                          title={
                            <React.Fragment>                             
                              <h5 className="h5heading">
                                {datas.details}
                              </h5>
                            </React.Fragment>
                          }
                        >
                          <Button className="adminPannel_i_icons">
                            <RiInformation2Line />
                          </Button>
                        </HtmlTooltip>
                    </td>
                    <td>
                      <button
                        style={{
                          border: "none",
                          backgroundColor: "transparent",
                          cursor: "pointer",
                        }}
                        onClick={() => handleSendDocument(datas)}
                      >
                        <IoEyeSharp
                          style={{ color: "white", fontSize: "21px" }}
                          className="eye_iconHover"
                        />
                      </button>
                      <button
                        style={{
                          border: "none",
                          backgroundColor: "transparent",
                          cursor: "pointer",
                        }}
                        onClick={() => handleViewApplications(datas.id)}
                      >
                        <FaAmazonPay
                          style={{ color: "white", fontSize: "21px" }}
                          className="eye_iconHover"
                        />
                      </button>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </main>
    </>
  );
};

export default UserDetails;








