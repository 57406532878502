import React, { useContext, useEffect, useState } from "react";
// import io from "socket.io-client";
import {
  BsFillArchiveFill,
  BsFillGrid3X3GapFill,
  BsPeopleFill,
  BsFillBellFill,
} from "react-icons/bs";
import { Link, useNavigate } from "react-router-dom";
import VisaContext from "../../../context/visa-context";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import { GrUserManager } from "react-icons/gr";
import { FaSchoolFlag } from "react-icons/fa6";
import { FaCcVisa } from "react-icons/fa6";

const Home = () => {
  const [notifications, setNotifications] = useState([]);
  const [visa, setVisa] = useState({
    destination: "",
    name: "",
    email: "",
    file: null,
  });

  // Modal
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  // Modal
  const navigate = useNavigate();
  const ctxApi = useContext(VisaContext);

  // useEffect(() => {
  //   const fetchUnreadNotifications = async () => {
  //     try {
  //       const response = await fetch(
  //         "https://backend-visa2.vercel.app/adminApi/fetch-unread-notifications",
  //         {
  //           headers: {
  //             Authorization: `Bearer ${localStorage.getItem("admin-token")}`,
  //           },
  //         }
  //       );
  //       if (!response.ok) {
  //         throw new Error(`Error fetching notifications: ${response.statusText}`);
  //       }

  //       const data = await response.json();
  //       setNotifications(data.notifications.reverse()); // Show latest notifications first
  //     } catch (error) {
  //       console.error("Error fetching notifications:", error);
  //     }
  //   };

  //   fetchUnreadNotifications();

  //   const isAdminSite = window.location.hostname === 'localhost' || window.location.hostname === 'admin.vijatour.com';
  //   let socket = null;

  //   if (isAdminSite) {
  //     socket = io('https://backend-visa2.vercel.app' || 'http://localhost:4000' , {
  //       transports: ['polling'],
  //       withCredentials: true,
  //       extraHeaders: {
  //         'my-custom-header': 'value',
  //       },
  //     });

  //     socket.on("notification", handleNotification);

  //     socket.on("connect_error", (err) => {
  //       console.error("Socket.IO connect_error:", err);
  //     });

  //     socket.on("error", (err) => {
  //       console.error("Socket.IO error:", err);
  //     });
  //   }

  //   return () => {
  //     if (socket) {
  //       socket.off("notification", handleNotification);
  //     }
  //   };
  // }, []);

  // const handleNotification = (data) => {
  //   setNotifications((prevNotifications) => [data, ...prevNotifications]);
  // };

  // const handleNotificationClick = async () => {
  //   try {
  //     const response = await fetch(
  //       "https://backend-visa2.vercel.app/adminApi/mark-notifications-read",
  //       {
  //         method: "POST",
  //         headers: {
  //           Authorization: `Bearer ${localStorage.getItem("admin-token")}`,
  //           "Content-Type": "application/json",
  //         },
  //       }
  //     );
  //     if (!response.ok) {
  //       throw new Error(`Error marking notifications as read: ${response.statusText}`);
  //     }
  //     setShowUserNames(true);
  //   } catch (error) {
  //     console.error("Error marking notifications as read:", error);
  //   }
  // };

  // const handleGetData = () => {
  //   const ids = notifications.map((notification) => notification.userId).join(',');
  //   navigate(`/user?ids=${ids}`);
  // };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setVisa((pre) => ({
      ...pre,
      [name]: value,
    }));
  };
  const handleFileChange = (e) => {
    const file = e.target.files[0];
    setVisa((prevVisa) => ({
      ...prevVisa,
      file: file,
    }));
  };
  // console.log("visa-file",visa)

  const handleSubmit = async (e) => {
    e.preventDefault();

    const formData = new FormData();
    formData.append("destination", visa.destination);
    formData.append("name", visa.name);
    formData.append("email", visa.email);
    formData.append("file7", visa.file);

    try {
      const response = await fetch(
        "https://backend-visa2.vercel.app/adminApi/upload/visaFile",
        {
          method: "POST",
          body: formData,
        }
      );

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const data = await response.json();
      // close the form 💯
      handleClose();
      setVisa({
        destination: "",
        name: "",
        email: "",
        file: null,
      });
    } catch (error) {
      console.error("There was an error!", error);
    }
  };

  return (
    <main className="main-container">
      <div className="main-title">
        <h3>DASHBOARD</h3>
      </div>

      <div className="main-cards">
        <div className="card">
          <div className="card-inner">
            <h3>CUSTOMERS</h3>
            <Link
              to={"/user"}
              style={{ listStyle: "none", fontSize: "25px", color: "white" }}
            >
              <BsPeopleFill className="card_icon" />
            </Link>
          </div>
          <h1 style={{ color: "white" }}>{ctxApi.users.length}</h1>
        </div>
        <div className="card">
          <div className="card-inner">
            <h3>APPLICATIONS</h3>
            <Link
              to={"/applications"}
              style={{ listStyle: "none", fontSize: "25px", color: "white" }}
            >
              <BsFillGrid3X3GapFill className="card_icon" />
            </Link>
          </div>
          <h1 style={{ color: "white" }}>{ctxApi.applicatons.length}</h1>
        </div>
        <div className="card">
          <div className="card-inner">
            <h3>PRODUCTS</h3>
            <BsFillArchiveFill className="card_icon" />
          </div>
          <h1
            style={{
              display: "inline-block",
              marginBottom: "2px",
              color: "white",
            }}
          >
            90{" "}
          </h1>{" "}
          <span style={{ fontSize: "20px", color: "white" }}>COUNTRIES</span>
        </div>

        <div className="card">
          <div className="card-inner">
            <h3>Notifications</h3>
            <BsFillBellFill
              style={{ cursor: "pointer" }}
              className="card_icon"
            />
          </div>
          <h1 style={{ color: "white" }}>{notifications.length}</h1>
        </div>
        {/* {showUserNames && (
        <div>
          <h4>New Users:</h4>
          <ul>
            {notifications.map((notification, index) => (
              <li key={index} style={{ listStyle: "none", cursor: "pointer" }}>
                {notification.users}
              </li>
            ))}
          </ul>
          <button onClick={handleGetData}>Show</button>
        </div>
      )} */}
        <div className="card">
          <div className="card-inner">
            <h3>Freelancers</h3>
            <Link
              to={"/freelancer"}
              style={{ listStyle: "none", fontSize: "25px", color: "white" }}
            >
              <GrUserManager
                style={{ cursor: "pointer" }}
                className="card_icon"
              />
            </Link>
          </div>
          <h1 style={{ color: "white" }}>{ctxApi.freeData.length} </h1>
        </div>
        <div className="card">
          <div className="card-inner">
            <h3>TMS</h3>
            <Link
              to={"/tmsdata"}
              style={{ listStyle: "none", fontSize: "25px", color: "white" }}
            >
              <FaSchoolFlag
                style={{ cursor: "pointer" }}
                className="card_icon"
              />
            </Link>
          </div>
          <h1 style={{ color: "white" }}>{ctxApi.tmsData.length} </h1>
        </div>
        <div className="card">
          <div className="card-inner">
            <h3>Work Permit Visa</h3>
            <Link
              to={"/allWorkvisaApplications"}
              style={{ listStyle: "none", fontSize: "25px", color: "white" }}
            >
              <FaCcVisa
                style={{ cursor: "pointer" }}
                className="card_icon"
              />
            </Link>
          </div>
          <h1 style={{ color: "white" }}>{ctxApi.workApplications.length} </h1>
        </div>
      </div>

      <div className="upload_doc">
        <button onClick={handleShow}>Upload Here...</button>
      </div>

      <div className="modallll">
        <Modal show={show} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>Upload File</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form>
              <Form.Group
                className="mb-3"
                controlId="exampleForm.ControlInput1"
              >
                <Form.Label>Destination</Form.Label>
                <Form.Control
                  name="destination"
                  value={visa.destination}
                  type="text"
                  placeholder="Country Name"
                  autoFocus
                  onChange={handleChange}
                />
              </Form.Group>
              <Form.Group
                className="mb-3"
                controlId="exampleForm.ControlInput1"
              >
                <Form.Label>Traveller Name</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Traveller Name"
                  autoFocus
                  name="name"
                  value={visa.name}
                  onChange={handleChange}
                />
              </Form.Group>
              <Form.Group
                className="mb-3"
                controlId="exampleForm.ControlInput1"
              >
                <Form.Label>User Email</Form.Label>
                <Form.Control
                  type="email"
                  placeholder="name@example.com"
                  autoFocus
                  name="email"
                  value={visa.email}
                  onChange={handleChange}
                />
              </Form.Group>
              <Form.Group controlId="formFileMultiple" className="mb-3">
                <Form.Label>Visa File</Form.Label>
                <Form.Control
                  onChange={handleFileChange}
                  type="file"
                  multiple
                />
              </Form.Group>
            </Form>
          </Modal.Body>

          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Close
            </Button>
            <Button variant="primary" onClick={handleSubmit}>
              Upload
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    </main>
  );
};

export default Home;
